/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages

				/**
				 * check and inject data if a user is coming from or 
				 * has visited a local dogtopia website
				 */
				lastLocalDogtopiaID = lastLocalDogtopiaName = lastLocalDogtopiaURL = "";

				if( typeof Storage !== "undefined" ) {
					if( 
						localStorage.getItem( "lastDogtopiaURL" ) &&
						!localStorage.getItem( "lastDogtopiaURL" ).includes( "franchise" ) &&
						!localStorage.getItem( "lastDogtopiaURL" ).includes( "promo" ) &&
						!localStorage.getItem( "lastDogtopiaURL" ).includes( "landing" )
				 	) {
						if( "lastDogtopiaID" in localStorage ) { 
							lastLocalDogtopiaID = localStorage.getItem( "lastDogtopiaID" );
						}

						if( "lastDogtopiaName" in localStorage ) { 
							lastLocalDogtopiaName = localStorage.getItem( "lastDogtopiaName" ).toLowerCase();
						}

						if( "lastDogtopiaURL" in localStorage ) {
							lastLocalDogtopiaURL = localStorage.getItem( "lastDogtopiaURL" );
						}
					}
				}

				if( lastLocalDogtopiaURL !== "" && $( '.meet-greet-url' ).length > 0 ) {
					$( '.meet-greet-url' ).attr( "href",
						lastLocalDogtopiaURL + '/new-pet-parent-info/' + '?utm_source=' + lastLocalDogtopiaName + 
						'&utm_medium=header+nav&utm_campaign=new+pet+parent+info'
					);
				}

				// Menu adjustment for new white nav menu
				$( document ).on( "breakpoint:changed", function( evt, newBreakpointName ) {
					if( ( newBreakpointName === "lg" ) || ( newBreakpointName === "xl" ) ) {

						// Prepend menu on anything but home page
						if( $( ".dogtopia-extra-nav-container" ).length > 0 &&
										 $( ".home-page-header-nav-v2" ).length == 0 ) {
							$( ".dogtopia-extra-nav-container" ).prependTo( ".dogtopia-header-extra-nav" );
						}

						// Prepend menu only on the home page
						else if( $( ".dogtopia-extra-nav-container" ).length > 0 &&
										 $( ".home-page-header-nav-v2" ).length > 0 ) {
							$( ".dogtopia-extra-nav-container" ).prependTo( ".home-page-header-extra-nav" );
						}
					}

					else if( $( ".dogtopia-extra-nav-container" ).length > 0  ) {
						$( ".dogtopia-extra-nav-container" ).prependTo( ".mobile-collapsible-nav-wrap .collapsible-nav-content-wrap" );
					}	
				} );


				breakpointObj.refreshValue = function(){

					if((typeof this.value) === "undefined"){
						this.value = "";
					}

					var newBreakpointName = window.getComputedStyle(document.querySelector("body"), ":before").getPropertyValue("content").replace(/\"/g, "");

					var valueChanged = true;

					if(this.value === newBreakpointName){
						valueChanged = false;
					}

					this.value = newBreakpointName;

					if(valueChanged){
						$("body").trigger("breakpoint:changed", [newBreakpointName]);
					}
				};

				var lastBreakpointName = breakpointObj.value;

				$(document).on("breakpoint:changed", function(evt, newBreakpointName){
					//console.log(evt);
					//console.log(newBreakpointName);

					if((newBreakpointName === "md") || (newBreakpointName === "lg") || (newBreakpointName === "xl")){

						if($(".mobile-collapsible-nav-wrap .collapsible-nav-content-wrap .secondary-header-nav").has(".sign-in-nav-item").length > 0){
							$(".mobile-collapsible-nav-wrap .collapsible-nav-content-wrap .secondary-header-nav").find(".sign-in-nav-item").insertBefore(".header-top-banner-elements .uk-grid .secondary-header-nav .store-locator-nav-item");
						}
					
					} else if($(".header-top-banner-elements").has(".secondary-header-nav .sign-in-nav-item").length > 0){
					
						$(".header-top-banner-elements .secondary-header-nav").find(".sign-in-nav-item").insertAfter(".mobile-collapsible-nav-wrap .collapsible-nav-content-wrap .secondary-header-nav .store-locator-nav-item");
					
					}

					toggleStickyPostSharingIcons(newBreakpointName, lastBreakpointName);

					setBlogGridHeight();

					$(".captioned-slideshow-wrap .slideshow-caption-holder").height("");

					lastBreakpointName = newBreakpointName;

				});

				toggleStickyPostSharingIcons(breakpointObj.value, "");

				toggleStickyNav();

				$(window).resize(toggleStickyNav);

				if((themeName !== undefined) && (themeName === "dogtopia-2018")){

					$(window).resize(function(){

						var navbarNav = $(".header-navbar-banner-wrap.uk-sticky").find(".uk-navbar-nav");

						centerNationalHeaderNav(navbarNav);

					});

					$(".header-navbar-banner-wrap")
						.on("active", function(evt){

							$(this).find(".uk-navbar-nav").css("marginLeft", "");

						})
						.on("inactive", function(evt){

							var navbarNav = $(this).find(".uk-navbar-nav");

							centerNationalHeaderNav(navbarNav);

						})
						.find(".uk-navbar-nav").each(function(){

							if($(window).width() >= stickyNavBreakpoint){
								$(this).css("margin", "0 auto");
							}

							var navbarNav = $(this);

							window.setTimeout(function(){

								centerNationalHeaderNav(navbarNav);

							}, 500);

						});

				}

				$("#mobile-nav-toggler").on("click", function(){

					$(this).toggleClass("uk-open");
					$("body").toggleClass("mobile-nav-open");

				});

				$("#franchising-national-nav-toggler").on("click", function(){

					$(this).toggleClass("uk-open");
					$("body").toggleClass("national-nav-open");

					if($(this).hasClass("uk-open")){

						$(".header-top-banner-wrap a[data-open-state-href]").each(function(){

							$(this)
								.data("orig-state-href", $(this).attr("href"))
								.attr("href", $(this).data("open-state-href"));

						});


					}else{

						$(".header-top-banner-wrap a[data-open-state-href]").each(function(){

							$(this)
								.attr("href", $(this).data("orig-state-href"))
								.removeAttr("data-orig-state-href");

						});

					}

				});

				$(".collapsible-nav-wrap").find(".uk-navbar-dropdown")
					.on("beforeshow", function(){
						return false;
					})
					.on("beforehide", function(){
						return false;
					});

				$(".collapsible-nav-wrap .uk-navbar-nav").on("click", ".menu-item-has-children > a", function(evt){

					if($(this).hasClass("uk-open")){

						$(this).removeClass("uk-open");

					}else{

						$(this).addClass("uk-open");

					}

					var dropdown = $(this).next(".uk-navbar-dropdown");

					if(dropdown.hasClass("uk-open")){

						dropdown.removeClass("uk-open").height("");

					}else{

						dropdown.addClass("uk-open").height($(this).next(".uk-navbar-dropdown").get(0).scrollHeight);

					}

				});

				$(".image-slideshow .uk-slideshow-items > li")
					.on("itemhide", function(evt){

						var slideshowObj = null;

						if(evt.detail.length > 0){
							slideshowObj = evt.detail[0];
						}

						var parentSlideshow = $(this).closest(".image-slideshow");

						parentSlideshow.find(".slideshow-current-slide-status .js-text-content").text((slideshowObj.getIndex() + 1) + "/" + slideshowObj.length);

					});

				$(".captioned-slideshow-wrap").each(function(){

					if($(".slideshow-caption-holder", this).has(".slideshow-caption-slide").length === 0){
						$(".slideshow-caption-holder", this).append('<div class="slideshow-caption-slide">');
					}

					if(
						$(".image-slideshow .uk-slideshow-items .uk-active", this).has(".slide-copy-holder").length > 0 &&
						$(".slideshow-caption-holder .slideshow-caption-slide:first-child", this).html() === ""
					){

						$(".slideshow-caption-holder .slideshow-caption-slide:first-child", this).html($(".image-slideshow .uk-slideshow-items .uk-active .slide-copy-holder", this).html());

					}

					var slideshowWrap = this;

					$(".image-slideshow .uk-slideshow-items > li", this)
						.on("beforeitemhide", function(evt){

							var slideshowObj = null;

							if(evt.detail.length > 0){
								slideshowObj = evt.detail[0];
							}

							var slideToBeShown = $(".image-slideshow .uk-slideshow-items > li", slideshowWrap).eq(slideshowObj.getIndex());

							if(slideToBeShown.find(".slide-copy-holder").length > 0){

								var newSlideDiv = $("<div>")
									.addClass("slideshow-caption-slide next")
									.html(slideToBeShown.find(".slide-copy-holder").html());

								$(".slideshow-caption-holder", slideshowWrap)
									.append(newSlideDiv)
									.height(newSlideDiv.prop("scrollHeight"));

							}

						}).on("itemhide", function(evt){

							var slideshowObj = null;

							if(evt.detail.length > 0){
								slideshowObj = evt.detail[0];
							}

							// Make the old slide the previous slide
							$(".slideshow-caption-holder .slideshow-caption-slide:not('.next')", slideshowWrap).addClass("prev");

							// Make the new slide the current slide
							$(".slideshow-caption-holder .slideshow-caption-slide.next", slideshowWrap).removeClass("next");

						}).on("itemhidden", function(evt){

							$(".slideshow-caption-holder .slideshow-caption-slide.prev", slideshowWrap).remove();

						});

				});

				$(document).on("gform_post_render", function(){
					$('.ginput_container:not(.ginput_container_fileupload) > input[type!="hidden"], .ginput_container > select')
						.each(function(){
							if($(this).val() !== ""){
								$(this).closest(".gfield:not(.gfield_multi_time)").addClass("has-focus-or-value");
							}
						});
				});

				$(".gform_wrapper")
					.on("focus", '.ginput_container:not(.ginput_container_fileupload) > input[type!="hidden"], .ginput_container > select', function(){
						$(this).closest(".gfield:not(.gfield_multi_time)").addClass("has-focus-or-value");
					})
					.on("blur", '.ginput_container:not(.ginput_container_fileupload) > input[type!="hidden"], .ginput_container > select', function(){
						if($(this).val() === ""){
							$(this).closest(".gfield:not(.gfield_multi_time)").removeClass("has-focus-or-value");
						}
					});

				// Blog grid list functions
				if($("#blog-list-holder").length > 0){
					window.setTimeout(setBlogGridHeight, 200);
				}

				// Content list infinite feed
				if($(".load-more-btn").length > 0){

					if($("#list-content-loader").length < 1){
						$(".load-more-btn").hide();
					}

					$(".load-more-btn").on("click", function(evt){

						evt.preventDefault();

						var contentLoader = $("#list-content-loader");

						var loadMoreBtn = $(this);

						var origButtonText = loadMoreBtn.html();

						if(loadMoreBtn.is(".loading")){
							return false;
						}

						loadMoreBtn.addClass("loading");

						var action = "get_posts";

						if((contentLoader.data("action") !== undefined) && (contentLoader.data("action") !== "")){
							action = contentLoader.data("action");
						}

						$.post(
							ajaxVars.ajaxurl,
							{
								"action": action,
								"data": $.param(contentLoader.data())
							},
							function(data){

								contentLoader.remove();

								if(data){

									loadMoreBtn
										.attr("disabled", false)
										.removeClass("loading");

									var newBlogPosts = $($.parseHTML(data));

									newBlogPosts.filter(".blog-list-item").addClass("loading");

									$("#blog-list-holder").append(newBlogPosts);

									window.setTimeout(setBlogGridHeight, 1500);

									// Look for the new content loader tag
									// If it's not there, there aren't any more posts to show so hide the view more button
									if($("#list-content-loader").length < 1){

										loadMoreBtn.remove();

										$(".no-more-results-msg").show();

									}

								}else{

									loadMoreBtn.remove();

									$(".no-more-results-msg").show();

								}

							}
						);

					});

				}

				$(".faq-category-item-list-block .uk-accordion").on("show", function(){

					$(".faq-category-item-list-block .uk-accordion").not(this).each(function(){

						// Find the open FAQ item
						var openItemIndex = $(this).children(".uk-open").index();

						if(openItemIndex >= 0){
							UIkit.accordion(this).toggle(openItemIndex);
						}

					});

				});

				$.get(
					"https://geolocation-db.com/json/",
					{},
					function(geopIPData){

						if(geopIPData.country_code === "CA"){

							$('a[href*="www.dogtopiafoundation.org"]').attr("href", "https://www.dogtopiafoundationca.org");

						}

					},
					"json"
				);

				lastWindowSize = $(window).width();

				$(window).resize(function(){
					lastWindowSize = $(window).width();
				});

				$(window).resize(function(){
					breakpointObj.refreshValue();
				}).resize();



				/**
				 * Script for the preferred partners template
				 */
				if( $( '.preferred-partners-page-container' ).length > 0 ) {

					var scrollToItem = function() {

						// If window is smaller than 960px
						if( $( window ).width() < 960 ) {
							
							// Watch the accordion for an item to be shown
							UIkit.util.on( 'ul.uk-accordion li', 'shown', function() {

								// Scroll to top of item
								$( this ).get(0).scrollIntoView( { behavior: 'smooth' } );
							} );
						}
					};

					scrollToItem();
					
					// Check if window is resized
					$( window ).resize( function() {
						scrollToItem();
					} );
				}







			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page

				if($("#home-page-promo-slideshow-copy-wrap").has(".slideshow-copy-slide").length === 0){
					$("#home-page-promo-slideshow-copy-wrap").append('<div class="slideshow-copy-slide">');
				}

				if(
					$("#home-page-promo-slideshow .uk-slideshow-items .uk-active").has(".slide-copy-holder").length > 0 &&
					$("#home-page-promo-slideshow-copy-wrap .slideshow-copy-slide:first-child").html() === ""
				){

					$("#home-page-promo-slideshow-copy-wrap .slideshow-copy-slide:first-child").html($("#home-page-promo-slideshow .uk-slideshow-items .uk-active .slide-copy-holder").html());

				}

				$("#home-page-promo-slideshow .uk-slideshow-items > li")
					.on("beforeitemhide", function(evt){

						var slideshowObj = null;

						if(evt.detail.length > 0){
							slideshowObj = evt.detail[0];
						}

						var directionClass = (slideshowObj.dir === 1) ? "right" : "left";

						var slideToBeShown = $("#home-page-promo-slideshow .uk-slideshow-items > li").eq(slideshowObj.getIndex());

						if(slideToBeShown.find(".slide-copy-holder").length > 0){

							var newSlideDiv = $("<div>")
													.addClass("slideshow-copy-slide next " + directionClass)
													.html(slideToBeShown.find(".slide-copy-holder").html());

							if(slideshowObj.dir === 1){

								$("#home-page-promo-slideshow-copy-wrap").append(newSlideDiv);

							}else{

								$("#home-page-promo-slideshow-copy-wrap").prepend(newSlideDiv);

							}

						}

					}).on("itemhide", function(evt){

						var slideshowObj = null;

						if(evt.detail.length > 0){
							slideshowObj = evt.detail[0];
						}

						var directionClass = (slideshowObj.dir === 1) ? "right" : "left";

						// Make the old slide the previous slide
						$("#home-page-promo-slideshow-copy-wrap .slideshow-copy-slide:not('.next')")
							.removeClass("right left")
							.addClass(directionClass + " prev");

						// Make the new slide the current slide
						$("#home-page-promo-slideshow-copy-wrap .slideshow-copy-slide.next").removeClass("next");

					}).on("itemhidden", function(evt){

						$("#home-page-promo-slideshow-copy-wrap .slideshow-copy-slide.prev").remove();

					});

				if($("#testimonial-slides-switcher").length > 0){

					var testimonialSwitcher = UIkit.switcher("#testimonial-slides-switcher", {
						connect: '#testimonial-switcher-slides',
						animation: 'uk-animation-fade',
						duration: 400
					});

					window.setInterval(function(){

						var activeItem = $("#testimonial-switcher-slides > li.uk-active");

						var currItemIndex = $("#testimonial-switcher-slides > li").index(activeItem);

						var nextItemIndex = currItemIndex + 1;

						if(nextItemIndex >= $("#testimonial-switcher-slides > li").length){

							nextItemIndex = 0;

						}

						testimonialSwitcher.show(nextItemIndex);

					}, 10000);

				}

			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		'page_experts': {
			init: function(){

				$(document).on("gform_confirmation_loaded", function(){

					$(".experts-ask-question-modal .form-column .legal-text").hide();

				});

			},
			finalize: function(){

			}
		},
		'single_partner_promo': {
			init: function() {
				console.log( 'single partner promo' );
				if( $( '.option-b-cta-container' ).length == 0 ) {
					console.log( 'option a template' );
					
					var form 			= document.getElementById( "locationsForm" );
					var input 		= document.getElementById( "daycare-input" );
					var pageID		= $( "#page-id" ).val();
					var searchBox = new google.maps.places.SearchBox( input );
					var lat = '';
					var lng = '';

					function locationAjax( lat, lng ) {
						jQuery.ajax( {
							url: '/wp-admin/admin-ajax.php',
							type: 'POST',
							data: {
								action: 'pp_get_locations',
								lat: lat,
								lng: lng,
								id: pageID,
							},

							beforeSend: function() {
								$( '.pp-location-list' ).empty();
								$( '.location-instruction' ).hide();
								$( '.promo-submission' ).prop( 'disabled', true );
								$( '.loader-container' ).show();
								$( '.location-instruction' ).html( 'Select daycare' );
							},

							success: function( response ) {
								$( '.loader-container' ).hide();
								$( '.location-instruction' ).show();
								$( response ).appendTo( '.pp-location-list' );
							},

							error: function(err){
								// do error checking
								console.log("something went wrong");
								console.error(err);
							}
						} );
					}

					/**
					 * get user location on page load, run ajax function
					 */
					$.getJSON( "https://api.ipregistry.co/?key=6149vez1a59igr", function ( response ) {
						lat = response.location.latitude;
						lng = response.location.longitude;

						locationAjax( lat, lng );
					} );

					form.addEventListener( 'submit', function(e) {
						 e.preventDefault();
					} );

					$( '#daycare-input' )
						.on( 'focus', function () {
							// if( $( this ).val() !== "" ) {
								$( this ).closest( ".location-field" ).addClass( "has-focus-or-value" );
							// }
						} )
						.on( 'click', function () {
							// if( $( this ).val() !== "" ) {
								$( this ).closest( ".location-field" ).addClass( "has-focus-or-value" );
							// }
						} )
						.on( 'blur', function () {
							// if( $( this ).val() !== "" ) {
								$( this ).closest( ".location-field" ).addClass( "has-focus-or-value" );
							// }
						} );
					// 	.off( 'focus, blur', function () {
					// 		if( $( this ).val() === "" ) {
					// 			$( this ).closest( ".location-field" ).removeClass( "has-focus-or-value" );
					// 		}
					// } );
			

					searchBox.addListener( "places_changed", function() {
						var places = searchBox.getPlaces();

						if( places.length == 0 ) { return; }
								
						lat = places[0].geometry.location.lat();
						lng = places[0].geometry.location.lng();

						locationAjax( lat, lng );
					} );

					$( '.pp-location-list' ).on( 'click', '.change-location', function(e) {
						e.preventDefault();

						$( this ).text( 'Change' );
						$( this ).removeClass( 'change-location' );
						$( this ).addClass( 'reset-location' );
						$( '.pp-location-list' ).empty();

						var selectedCard = $( this ).closest( ".location-card" ).prop( 'outerHTML' );
						
						$( selectedCard ).appendTo( '.pp-location-list' );

						$( [ document.documentElement, document.body ] ).animate( {
							scrollTop: $( '#locationsForm' ).offset().top
						}, 400 );

						$( '.location-instruction' ).html( 'Daycare selected' );
						$( '.promo-submission' ).prop( 'disabled', false );
					} );

					$( '.pp-location-list' ).on( 'click', '.reset-location', function(e) {
						e.preventDefault();

						locationAjax( lat, lng );
					} );

					$( '.promo-submission' ).on( 'click', function() {
						var siteID = $( ".location-card .site-id" ).val();

						window.location.href = '/dashboard/meet-and-greet/?promo=' + pageID + '-' + siteID;
					});
				}
			},

				finalize: function(){

				}
			}
		};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

	var breakpointObj = {};

	var stickyNavBreakpoint = 1230;

	var lastWindowSize = 0;

	var centerNationalHeaderNav = function(navbarNav){

		if($(window).width() >= stickyNavBreakpoint){

			if(typeof navbarNav.data("origWidth") === "undefined"){
				navbarNav.data("origWidth", navbarNav.outerWidth());
			}

			if(navbarNav.closest(".uk-sticky").is(":not(.uk-active)")){

				var marginLeft = ((navbarNav.parent().outerWidth() - navbarNav.data("origWidth")) / 2);

				navbarNav.css("marginLeft", marginLeft);

			}

		}

	};

	var toggleStickyNav = function(){

		if(lastWindowSize === $(window).width()){
			return;
		}

		if($(window).width() >= stickyNavBreakpoint){

			if(!$(".header-navbar-banner-wrap").hasClass("uk-sticky")){

				UIkit.sticky(".header-navbar-banner-wrap", {
					animation:	"uk-animation-slide-top",
					showOnUp:	true,
					offset: 	(($("#wpadminbar").length > 0) ? 32 : 0)
				});
			}

		}else if($(".header-navbar-banner-wrap").hasClass("uk-sticky")){

			var stickyComponent = UIkit.getComponent($(".header-navbar-banner-wrap").get(0), "sticky");

			if(stickyComponent){
				stickyComponent.$destroy();
			}

			$(".header-navbar-banner-wrap")
				.removeClass("uk-sticky uk-active")
				.find(".uk-navbar-nav")
					.attr("style", "");

		}

	};

	var setBlogGridHeight = function(){

		if(!$("#blog-list-holder").hasClass("blog-list-masonry-grid")){
			return false;
		}

		var columnHeights = [];

		var numColumns;

		switch(breakpointObj.value) {

			case "sm":
			case "ms":
				numColumns = 2;
				break;

			case "md":
				numColumns = 3;
				break;

			case "lg":
			case "xl":
				numColumns = 4;
				break;

			default:
				numColumns = 1;
				break;

		}

		if(numColumns === 1){

			$("#blog-list-holder")
				.css("height", "")
				.children(".blog-list-item").css("order", "");


		}else{

			var numRows = $("#blog-list-holder").children(".blog-list-item").length / numColumns;

			for(var i = 0; i < numColumns; i++){
				columnHeights[i] = 0;
			}

			$("#blog-list-holder").children(".blog-list-item").each(function(i){

				var currColNum = (i % numColumns);

				columnHeights[currColNum] += ($(this).get(0).getBoundingClientRect().height + parseInt($(this).css("margin-top"), 10));

				$(this).css("order", (currColNum + 1));

			});

			var blogGridHeight = 0;

			for(i = 0; i < numColumns; i++){

				if(columnHeights[i] > blogGridHeight){
					blogGridHeight = columnHeights[i];
				}

			}

			$("#blog-list-holder").css("height", Math.ceil(blogGridHeight));

		}

		$("#blog-list-holder").children(".blog-list-item").removeClass("loading");

	};

	var toggleStickyPostSharingIcons = function(newBreakpointName, lastBreakpointName){

		if(typeof lastBreakpointName === "undefined"){
			lastBreakpointName = "";
		}

		var enabledAtBreakpoints = ['lg', 'xl'];

		// If the current breakpoint is 'lg' or 'xl' and the old breakpoint was smaller than that, enable the sticky sharing icons
		if((lastBreakpointName === "") || ((enabledAtBreakpoints.indexOf(newBreakpointName) >= 0) && (enabledAtBreakpoints.indexOf(lastBreakpointName) === -1))){

			$("#sticky-post-sharing-icons")
				.stick_in_parent({
					offset_top: 45
				})
				.on("sticky_kit:stick", function(evt){
					stickPostSharingIcons(evt.target);
				})
				.on("sticky_kit:unstick", function(evt){
					unstickPostSharingIcons(evt.target);
				})
				.on("sticky_kit:bottom", function(evt){
					unstickPostSharingIcons(evt.target);
				})
				.on("sticky_kit:unbottom", function(evt){
					stickPostSharingIcons(evt.target);
				});

		}

	};

	var stickPostSharingIcons = function(target){

		$(target).addClass("has-position-fixed");

		var parentPosData = $(target).parent().get(0).getBoundingClientRect();

		$(target).css("left", (parentPosData.left - 60));

	};

	var unstickPostSharingIcons = function(target){

		$(target).removeClass("has-position-fixed");

		$(target).css("left", "");

	};

})(jQuery); // Fully reference jQuery after this point.
